import { ThemeProvider, CssBaseline, Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import Nav from "../components/navigation/Nav";
import { theme } from "../themes/themes";
import { graphql } from 'gatsby';
import PageContainer from "../components/layout/PageContainer";
import PageHeroDescription from "../components/PageHeroDescription";
import Seo from "../components/Seo";
import { resolutionAxes, getBreakpointMatch } from "../utils/helpers/breakpointsHelpers";
import CompareButton from "../components/navigation/CompareButton";
import GroupableDeviceGrid from "./GroupableDeviceGrid";
import { useAtom } from "jotai";
import { breakpointsAtom, deviceGroupBy } from "../utils/state/devicesState";
import ViewControlBar from "../components/view-controls/ViewControlBar";
import { prettifiedProducts } from "../utils/helpers/prettificationHelpers";
import Footer from "../components/navigation/Footer";

const groupByDefault = {
	brand: "breakpoints",
	category: "breakpoints",
	breakpoint: "brand"
}

const CategoryPageTemplate = ({ data, location, pageContext, ...otherProps }) => {
	const { categoryType } = pageContext
	const isBreakpoints = categoryType === "breakpoint"
	const products = prettifiedProducts(data.allDevicesJson.nodes)
	const [breakpoints] = useAtom(breakpointsAtom)
	const [groupBy, setGroupBy] = useAtom(deviceGroupBy)

	useEffect(() => {
		setGroupBy(groupByDefault[categoryType])
	}, [])

	const getProductsFilteredByBreakpoint = () => {
		return products.filter(device => {
			const { width } = resolutionAxes(device)
			const { fullMatch } = getBreakpointMatch(breakpoints, width)

			return fullMatch.name === pageContext.breakpoint.name
		})
	}

	const heading = !isBreakpoints ? `${pageContext[categoryType]} Device Sizes` : `${pageContext[categoryType].fullName} Breakpoint Device Sizes`
	const filteredProducts = !isBreakpoints ? products : getProductsFilteredByBreakpoint()


	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Seo
				passedTitle={heading}
				passedDescription={`A collection of ${heading}`}
			/>
			<Nav
				location={location}
			>
				<CompareButton />
			</Nav>
			<PageContainer>
				<PageHeroDescription
					description={heading}
				/>
				<GroupableDeviceGrid
					products={filteredProducts}
					groupBy={groupBy}
				/>
			</PageContainer>
			<Footer/>
		</ThemeProvider>
	)
}

export const query = graphql`
    query categoryPageQuery($brand: String) {
		allDevicesJson(filter: {Product: {Brand: {eq: $brand}}}) {
			nodes {
				Product {
					Brand
					Model
				}
				Display {
					Color_Depth
					Diagonal
					Height
					Illumination
					Notch_es_
					Pixel_Size
					Pixel_Density
					Refresh_Rate
					Resolution__H_x_W_
					Subpixels
					Touch_screen_Type
					Type
					Width
					Touch_Point_s_
				}
				Time {
					Announced
					Released
				}
				Category
			}
		}
    }
`

export default CategoryPageTemplate;


